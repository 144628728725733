$(document).ready(function() {
    update_new_to_therapy($('input:radio[name="radioNewToTherapy"]:checked'), false);
    update_insurance_type($('input:radio[name="insuranceCoverageTypeId"]:checked'), false);
    update_hiv_question($("#prescribedMedication"), false);
    update_sub_sections($('input:radio[name="radioUserTypeId"]:checked'), false);
    update_patient_advocate_name_sections($('input:radio[name="pointOfContact"]:checked'), false);
    update_patient_info_sections($('input:checkbox[name="sameAddress"]:checked'), false);
    update_other_relation_to_patient_field($('#relWithPatient'), false);
    update_zaxine_coverage($('input:radio[name="zaxineCoverageAnswer"]:checked'), false);
    update_expedite_transplant_type($('input:radio[name="prescribed_medication"]:checked'), false);
    update_expedite_statement_label($('input:radio[name="insuranceCoverageTypeId"]:checked'), false);
    update_expedite_required_email($('input:radio[name="methodOfCommunication"]:checked'), false);
    update_expedite_advocate_required_email($('input:radio[name="advocateMethodOfCommunication"]:checked'), false);

    $('input:radio[name="radioNewToTherapy"]').on("change",function () {
        update_new_to_therapy($(this), true);
    });

    $('input:radio[name="insuranceCoverageTypeId"]').on("change",function () {
        update_insurance_type($(this), true);
        update_expedite_statement_label($(this), true);
    });

    $("#prescribedMedication").on("change",function () {
        update_hiv_question($(this), true);
    });

    $('input:radio[name="radioHIVPositive"]').on("change",function () {
        show_hiv_modal($(this));
    });

    $('input:radio[name="radioUserTypeId"]').on("change",function () {
        update_sub_sections($(this), true);
    });

    $('input:checkbox[name="sameAddress"]').on("change",function () {
        update_patient_info_sections($(this), true);
    });

    $('input:radio[name="pointOfContact"]').on("change",function () {
        update_patient_advocate_name_sections($(this), true);
    });

    $('input:radio[name="zaxineCoverageAnswer"]').on("change",function () {
        update_zaxine_coverage($(this), true);
    });

    $('input:radio[name="prescribed_medication"]').on("change",function () {
        update_expedite_transplant_type($(this), true);
    });

    $('#relWithPatient').on("change",function () {
        update_other_relation_to_patient_field($(this), true);
    });

    $('input:radio[name="methodOfCommunication"]').on("change",function () {
        update_expedite_required_email($(this), true);
    });

    $('input:radio[name="advocateMethodOfCommunication"]').on("change",function () {
        update_expedite_advocate_required_email($(this), true);
    });
});

var transition_speed = "fast";

function update_new_to_therapy(object, show_transition) {
    if(show_transition == true){
        transition_speed = "fast";
    } else {
        transition_speed = 0;
    }

    if(object.val() == 'n'){
        $(".previousTherapy").show(transition_speed);
    } else {
        $(".previousTherapy").hide(transition_speed);
    }
}

function update_insurance_type(object, show_transition) {
    if (show_transition == true){
        transition_speed = "fast";
    } else {
        transition_speed = 0;
    }

    if (object.val() == '1'){
        $(".public_plan_details").show(transition_speed);
        $(".private_plan_details").hide(transition_speed);
        $(".zaxine-coverage").show(transition_speed);
    } else if (object.val() == '2') {
        $(".private_plan_details").show(transition_speed);
        $(".public_plan_details").hide(transition_speed);
        $(".zaxine-coverage").show(transition_speed);
    } else if (object.val() == '3') {
        $(".public_plan_details").show(transition_speed);
        $(".private_plan_details").show(transition_speed);
        $(".zaxine-coverage").show(transition_speed);
    } else if (object.val() == '4') {
        $(".public_plan_details").hide(transition_speed);
        $(".private_plan_details").hide(transition_speed);
        $(".zaxine-coverage").hide(transition_speed);
    } else {
        $(".public_plan_details").hide(transition_speed);
        $(".private_plan_details").hide(transition_speed);
        $(".zaxine-coverage").hide(transition_speed);
    }
}

function update_expedite_statement_label(object, show_transition) {
    if (show_transition == true){
        transition_speed = "fast";
    } else {
        transition_speed = 0;
    }

    if (object.val() == '1'){
        $(".file_upload").show(transition_speed);
        $(".application_status").show(transition_speed);
        $(".trilium_statement").show(transition_speed);
        $(".assessment_statement").hide(transition_speed);
        $(".eligibility_trilium_note").show(transition_speed);
        $(".eligibility_assessment_note").hide(transition_speed);

        $(".trillium_statement_warning").show(transition_speed);
        $(".assessment_statement_warning").hide(transition_speed);
    } else if (object.val() == '2') {
        $(".file_upload").show(transition_speed);
        $(".application_status").hide(transition_speed);
        $(".trilium_statement").hide(transition_speed);
        $(".assessment_statement").show(transition_speed);
        $(".eligibility_trilium_note").hide(transition_speed);
        $(".eligibility_assessment_note").show(transition_speed);

        $(".trillium_statement_warning").hide(transition_speed);
        $(".assessment_statement_warning").show(transition_speed);
    } else if (object.val() == '3') {
        $(".file_upload").show(transition_speed);
        $(".application_status").show(transition_speed);
        $(".trilium_statement").show(transition_speed);
        $(".assessment_statement").hide(transition_speed);
        $(".eligibility_trilium_note").show(transition_speed);
        $(".eligibility_assessment_note").hide(transition_speed);

        $(".trillium_statement_warning").show(transition_speed);
        $(".assessment_statement_warning").hide(transition_speed);
    } else {
        $(".file_upload").hide(transition_speed);
        $(".application_status").hide(transition_speed);
        $(".trilium_statement").hide(transition_speed);
        $(".assessment_statement").hide(transition_speed);
        $(".eligibility_trilium_note").hide(transition_speed);
        $(".eligibility_assessment_note").hide(transition_speed);

        $(".trillium_statement_warning").hide(transition_speed);
        $(".assessment_statement_warning").hide(transition_speed);
    }
}

function update_zaxine_coverage(object, show_transition) {
    if (show_transition == true){
        transition_speed = "fast";
    } else {
        transition_speed = 0;
    }

    if (object.val() == '796'){
        $(".zaxine-status").show(transition_speed);
    } else {
        $(".zaxine-status").hide(transition_speed);
    }
}

function update_hiv_question(object, show_transition){
    if(show_transition == true){
        transition_speed = "fast";
    } else {
        transition_speed = 0;
    }

    if(object.val() == '729'){
        $(".radioHIVPositive").show(transition_speed);
    } else {
        $(".radioHIVPositive").hide(transition_speed);
    }
}

function update_sub_sections(object, show_transition){
    if(show_transition == true){
        transition_speed = "fast";
    } else {
        transition_speed = 0;
    }

    if(object.val() == '1'){
        $(".register-form").show(transition_speed);
        $(".medical_information").show(transition_speed);
        $(".program_service_requested_details_hcp").show(transition_speed);
        $(".zydelig_details").hide(transition_speed);
        $(".program_service_requested_details_patient").hide(transition_speed);
        $(".email").hide(transition_speed);
        $(".hcp_consent").show(transition_speed);
        $(".patient_consent").hide(transition_speed);
    }
    else if (object.val() == '2'){
        $(".register-form").show(transition_speed);
        $(".medical_information").hide(transition_speed);
        $(".program_service_requested_details_hcp").hide(transition_speed);
        $(".zydelig_details").show(transition_speed);
        $(".program_service_requested_details_patient").show(transition_speed);
        $(".email").show(transition_speed);
        $(".hcp_consent").hide(transition_speed);
        $(".patient_consent").show(transition_speed);
    }
    else
    {
        $(".register-form").hide(transition_speed);
    }
}

function update_patient_info_sections(object, show_transition){
    if(show_transition == true){
        transition_speed = "fast";
    } else {
        transition_speed = 0;
    }
    if (object.is(':checked')) {
        $(".patientAddress").hide(transition_speed);
        $(".patientLocation").hide(transition_speed);
    }
    else {
        $(".patientAddress").show(transition_speed);
        $(".patientLocation").show(transition_speed);
    }

}

function update_patient_advocate_name_sections(object, show_transition){
    if(show_transition == true){
        transition_speed = "fast";
    } else {
        transition_speed = 0;
    }

    if (object.val() == '763' || object.val() == '830') {
        $(".patient-advocate-name").show(transition_speed);
    } else {
        $(".patient-advocate-name").hide(transition_speed);
    }
}

function update_other_relation_to_patient_field(object, show_transition){
    if(show_transition == true){
        transition_speed = "fast";
    } else {
        transition_speed = 0;
    }

    if (object.val() == '761' || object.val() == '847') {
        $(".other-relation-to-patient").show(transition_speed);
    } else {
        $(".other-relation-to-patient").hide(transition_speed);
    }
}

function update_expedite_transplant_type (object, show_transition) {
    if(object.val() == '823'){
        $(".advagraf").show(transition_speed);
        $(".prograf").hide(transition_speed);
    } else {
        $(".advagraf").hide(transition_speed);
        $(".prograf").show(transition_speed);
    }
}

function update_expedite_required_email (object, show_transition) {
    if(object.val() == '828'){
        $("#expedite_email").addClass('required');
        $("#expedite_confirm_email").addClass('required');
    } else {
        $("#expedite_email").removeClass('required');
        $("#expedite_confirm_email").removeClass('required');
    }
}

function update_expedite_advocate_required_email (object, show_transition) {
    if(object.val() == '835'){
        $("#expedite_advocate_email").addClass('required');
        $("#expedite_advocate_confirm_email").addClass('required');
    } else {
        $("#expedite_advocate_email").removeClass('required');
        $("#expedite_advocate_confirm_email").removeClass('required');
    }
}

function show_hiv_modal(object){
    if(object.val() == 'y'){
        $('#hiv-modal').modal();
    }
}

